function loadData(key) {
  try {
    let data = localStorage.getItem(key);
    data = JSON.parse(data);
    return data;
  } catch (err) {
    return undefined;
  }
}
function isActionAuth(model, key, _pageAuthentication) {
  try {
    if (_pageAuthentication !== undefined && _pageAuthentication.length) {
      let el = _pageAuthentication;
      if (el.length) {
        let modelFileter = el.find((e) => e.key === model);
        if (modelFileter) {
          let actions = modelFileter.actions.find((e) => e.key === key);
          if (actions) {
            return actions.access;
          } else {
            return false;
          }
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return true;
    }
  } catch (err) {}
}

function getToken() {
  try {
    return localStorage.getItem("token");
  } catch (err) {
    return undefined;
  }
}
function saveData(key, data) {
  localStorage.setItem(key, JSON.stringify(data));
  localStorage.setItem("token", data.token);
}
function deleteData(key) {
  localStorage.removeItem(key);
  localStorage.removeItem("token");
  sessionStorage.removeItem("session");
}
export { loadData, saveData, deleteData, getToken, isActionAuth };

export const dateFormat = (data) => {
  const dateTime = data?.forward_at?.split("T") || data?.split("T");
  let date = dateTime?.[0];
  let time = dateTime?.[1]?.slice(0, 5);

  if (date) {
    const [year, month, day] = date.split("-");
    date = `${day}-${month}-${year}`;
  }

  if (time) {
    const [hour, minutes] = time.split(":");
    let hourNum = parseInt(hour, 10);
    const suffix = hourNum >= 12 ? "PM" : "AM";

    if (hourNum === 0) {
      hourNum = 12;
    } else if (hourNum > 12) {
      hourNum -= 12;
    }
    time = `${hourNum}:${minutes} ${suffix}`;
  }

  return { date, time };
};

export const getCookie = (name) => {
  let cookieValue = null;
  if (document.cookie && document.cookie !== "") {
    const cookies = document.cookie.split(";");

    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i].trim();
      if (cookie.substring(0, name.length + 1) === name + "=") {
        cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
        break;
      }
    }
  }
  return cookieValue;
};

// find the
export function get_url_extension(url) {
  return url.split(/[#?]/)[0].split(".").pop().trim();
}

export function downloadExcelFile(file) {
  window.open(file, "_blank");
  const url = URL.createObjectURL(file);
  const a = document.createElement("a");
  a.href = url;
  a.download = "complaint.xlsx";
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
}

// for download pdf file

export function openBase64PDF(base64Data) {
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  const binaryData = atob(base64Data);
  const byteArray = new Uint8Array(binaryData.length);
  for (let i = 0; i < binaryData.length; i++) {
    byteArray[i] = binaryData.charCodeAt(i);
  }
  const blob = new Blob([byteArray], { type: "application/pdf" });
  const url = URL.createObjectURL(blob);

  if (isMobile) {
    const link = document.createElement("a");
    link.href = url;
    link.download = "document.pdf";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  } else {
    const pdfWindow = window.open("", "_blank");

    if (pdfWindow) {
      pdfWindow.document.open();
      pdfWindow.document.write(`
        <!DOCTYPE html>
        <html>
        <head>
          <title>PDF Viewer</title>
          <style>
            html, body {
              margin: 0;
              padding: 0;
              width: 100%;
              height: 100%;
              overflow: hidden;
            }
  
            iframe {
              border: none;
              width: 100%;
              height: 100%;
            }
  
          </style>
        </head>
        <body>
  
          <iframe src="${url}" type="application/pdf"></iframe>
        </body>
        </html>
      `);
      pdfWindow.document.close();
    }
  }
}

export function NormalPdfOpen(pdfUrl) {
  const pdfWindow = window.open("", "_blank");
  if (pdfWindow) {
    pdfWindow.document.open();
    pdfWindow.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
        <title>PDF Viewer</title>
        <style>
          html, body {
            margin: 0;
            padding: 0;
            width: 100%;
            height: 100%;
            overflow: hidden;
          }

          iframe {
            border: none;
            width: 100%;
            height: 100%;
          }

        </style>
      </head>
      <body>

        <iframe src="${pdfUrl}" type="application/pdf"></iframe>
      </body>
      </html>
    `);
    pdfWindow.document.close();
  } else {
    console.error("Failed to open new window.");
  }
}

// for download file
export const initiateDownload = (downloadLink) => {
  const anchor = document.createElement("a");
  anchor.style.display = "none";
  anchor.href = downloadLink;
  anchor.setAttribute("downlaod", downloadLink);
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};
