import React, { createContext, useEffect, useState } from "react";
import CryptoJS from "crypto-js";
import { getAPIdata, localeFunction, PostApiFunction } from "../utils";
import Apiconfigs from "../ApiConfig/Apiconfig";
import { initiateDownload, openBase64PDF } from "../utils/sessionStorage";
import swal from "sweetalert";
import { useHistory } from "react-router-dom";

export const AuthContext = createContext();

const base64Key = "w2IKah2csuNE77fv1Zd8+jzxcJ2GYh3WO5UZMqfHj3Y=";

export const setSession = (accessToken) => {
  if (accessToken) {
    window?.localStorage.setItem("token", accessToken);
    checkLogin();
  } else {
    window?.localStorage.removeItem("token");
    checkLogin();
  }
};

export const checkLogin = () => {
  const accessToken = window.localStorage.getItem("token");
  return accessToken ? true : false;
};

export const decryptData = (encryptedData) => {
  const key = CryptoJS.enc.Base64.parse(base64Key);
  const dataBytes = CryptoJS.enc.Base64.parse(encryptedData);
  const iv = CryptoJS.lib.WordArray.create(dataBytes.words.slice(0, 4));
  const ciphertext = CryptoJS.lib.WordArray.create(dataBytes.words.slice(4));
  const decryptedBytes = CryptoJS.AES.decrypt({ ciphertext }, key, { iv });
  const decryptedText = CryptoJS.enc.Utf8.stringify(decryptedBytes);
  return JSON.parse(decryptedText);
};

export default function AuthProvider(props) {
  const [profileData, setProfileData] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isLogin, setIsLogin] = useState(checkLogin());
  const [serch_compiant_data, setSearch_complaion_data] = useState("");
  const [_count, setTotalCount] = useState(1);
  // const [_getcsrf_token, setCsrf_Token] = useState("");
  const [selectedMenu, setSelectedMenu] = useState("");
  const empId = profileData?.employee_id;
  const [page, setPage] = useState(1);
  const history = useHistory();
  // const [baseFontSize, setBaseFontSize] = useState(16);

  const [_language, setLanguage] = React.useState(
    window.localStorage.getItem("languageId") != null
      ? window.localStorage.getItem("languageId")
      : "en"
  );
  const GetProfileFunction = async (token) => {
    try {
      const res = await PostApiFunction({
        endPoint: Apiconfigs.user_details,
        data: {
          token: window.localStorage.getItem("token") || token,
        },
      });
      if (res) {
        setProfileData(res);
        if (res?.is_time_valid === 0) {
          setSession("");
        } else {
          setSession(res?.token);
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  };
  // const csrf_token_Generate = async (token) => {
  //   try {
  //     const res = await getAPIdata({
  //       endPoint: Apiconfigs.csrf_token,
  //     });
  //     if (res) {
  //       sessionStorage.setItem("token", res?.csrfToken);
  //       setCsrf_Token(res?.csrfToken);
  //     }
  //   } catch (error) {
  //     console.log("error", error);
  //   }
  // };
  // for downlaod docment ppt expect pdf file
  function downloadBase64Doc(base64Data, fileName) {
    let mimeType;
    switch (fileName) {
      case ".document":
        mimeType = "application/msword";
        break;
      case ".docx":
        mimeType = "application/msword";
        break;
      case ".sheet":
        mimeType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        break;
      case ".xlsx":
        mimeType =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
        break;
      case ".xls":
        mimeType = "application/vnd.ms-excel";
        break;
      case ".presentation":
        mimeType = "application/vnd.ms-powerpoint";
        break;
      case ".octet-stream":
        mimeType = "application/octet-stream";
        break;
      case ".jpg":
        mimeType = "image/jpeg";
        break;
      case ".jpeg":
        mimeType = "image/jpeg";
        break;
      case ".png":
        mimeType = "image/png";
        break;
      case ".gif":
        mimeType = "image/gif";
        break;
      default:
        console.error("Unsupported file type");
        return;
    }
    const binaryData = atob(base64Data);
    const byteArray = new Uint8Array(binaryData.length);
    for (let i = 0; i < binaryData.length; i++) {
      byteArray[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([byteArray], { type: mimeType });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.style.display = "none";
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
  }

  // for watermark API pdf file
  const addWaterMarkFunc = async (name, id) => {
    try {
      setIsLoading(true);
      const res = await PostApiFunction({
        endPoint: Apiconfigs.waterMark,
        data: {
          id: id,
          name: profileData?.name,
          email: profileData?.user_email,
          title: name ? name : "",
        },
      });

      if (res?.exct === ".downlaod") {
        setIsLoading(false);
        initiateDownload(res?.result);
      } else if (res?.exct === ".pdf" || res?.exct === ".PDF") {
        setIsLoading(false);
        openBase64PDF(res?.result);
      } else {
        setIsLoading(false);
        downloadBase64Doc(res?.result, res?.exct);
      }
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  const serachCompaintApi = async () => {
    try {
      const res = await PostApiFunction({
        endPoint: Apiconfigs.search_complaint + `/?page=${page}`,
        data: {
          user_id: empId,
        },
      });

      if (res) {
        history?.push("/search-complaint");
        setSearch_complaion_data(res.result);
        setTotalCount(res.total_page);
      } else if (res?.status === 404) {
        swal({
          title: res?.result,
          icon: "warning",
          buttons: {
            custom: {
              text: "OK",
              value: "okBtn",
              className: "btn btn-primary",
              visible: true,
              closeModal: true,
            },
          },
        }).then((value) => {
          if (value === "okBtn") {
            history?.push("/");
          }
        });
      } else if (res?.code === "ERR_BAD_RESPONSE") {
        swal({
          title: res?.message,
          icon: "warning",
          buttons: {
            custom: {
              text: "OK",
              value: "okBtn",
              className: "btn btn-primary",
              visible: true,
              closeModal: true,
            },
          },
        }).then((value) => {
          if (value === "okBtn") {
            history?.push("/");
          }
        });
      } else {
        swal({
          title: res?.msg,
          icon: "warning",
          buttons: {
            custom: {
              text: "OK",
              value: "okBtn",
              className: "btn btn-primary",
              visible: true,
              closeModal: true,
            },
          },
        }).then((value) => {
          if (value === "okBtn") {
            history?.push("/");
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  // send email post API
  const SendEmailApi = async (user_type, complaint_id, user_email) => {
    try {
      const res = await PostApiFunction({
        endPoint: Apiconfigs.forward_email,
        data: {
          user_type: user_type,
          complaint_id: complaint_id,
          user_email: user_email,
        },
      });
      if (res) {
        // setProfileData(res);
        // if (res?.is_time_valid == 0) {
        //   setSession("");
        // } else {
        //   setSession(res?.token);
        // }
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    checkLogin();
    // csrf_token_Generate();
  }, []);

  useEffect(() => {
    GetProfileFunction();
  }, [isLogin]);

  const menuList = [
    {
      title: "Departmental Repository",
      path: "/menu1",
      img: "images/sidebar icon/Departmental-Respository-2.jpg",

      subItems: [
        {
          title: "Marketing",
          path: "/department-repository",
          type: "Departmental Repository",
          title1: "Departmental Repository",
        },
        {
          title: "Finance",
          path: "/department-repository",
          title1: "Departmental Repository",
        },

        {
          title: "Human Resource",
          path: "/department-repository",
          title1: "Departmental Repository",
        },

        {
          title: "Legal",
          path: "/department-repository",
          title1: "Departmental Repository",
        },

        {
          title: "Infotech",
          path: "/department-repository",
          title1: "Departmental Repository",
        },

        {
          title: "Publicity",
          path: "/department-repository",
          title1: "Departmental Repository",
        },

        {
          title: "Grievance",
          path: "/department-repository",
          title1: "Departmental Repository",
        },

        {
          title: "Call Center",
          path: "/department-repository",
          title1: "Departmental Repository",
        },

        {
          title: "Corporate Social Responsibility",
          path: "/department-repository",
          title1: "Departmental Repository",
        },

        {
          title: "Estate and Establishment",
          path: "/department-repository",
          title1: "Departmental Repository",
        },

        {
          title: "Field Risk Management",
          path: "/department-repository",
          title1: "Departmental Repository",
        },

        {
          title: "Pricing",
          path: "/department-repository",
          title1: "Departmental Repository",
        },

        {
          title: "Rajbhasha",
          path: "/department-repository",
          title1: "Departmental Repository",
        },

        {
          title: "Right to Information",
          path: "/department-repository",
          title1: "Departmental Repository",
        },

        {
          title: "Software Development Group",
          path: "/department-repository",
          title1: "Departmental Repository",
        },

        {
          title: "Underwriting",
          path: "/department-repository",
          title1: "Departmental Repository",
        },

        {
          title: "Learning and development",
          path: "/department-repository",
          title1: "Departmental Repository",
        },
        {
          title: "Emerging Markets",
          path: "/department-repository",
          title1: "Departmental Repository",
        },
      ],
    },
    {
      title: "Domain Repository",
      path: "/",
      img: "/images/sidebar icon/Domain-Repository-2.jpg",

      subItems: [
        {
          title: "Insurance",
          path: "/domain-repository",
          title1: "Domain Repository",
        },

        {
          title: "Agriculture",
          path: "/domain-repository",
          title1: "Domain Repository",
        },

        {
          title: "Economy",
          path: "/domain-repository",
          title1: "Domain Repository",
        },

        {
          title: "Law Library",
          path: "/domain-repository",
          title1: "Domain Repository",
        },
      ],
    },

    // {
    //   title: "Institutional Repository",
    //   path: "/",
    //   img: "/images/sidebar icon/Institutional-Repository-2.jpg",
    //   subItems: [
    //     {
    //       title: "Ministries",
    //       path: "/institutional-repository",
    //       title1: "Institutional Repository",
    //     },

    //     {
    //       title: "Agri Universities",
    //       path: "/institutional-repository",
    //       title1: "Institutional Repository",
    //     },

    //     {
    //       title: "Other Authorities",
    //       title1: "Institutional Repository",
    //       path: "/institutional-repository",
    //     },

    //     // {
    //     //   title: "Recycle bin",
    //     //   path: "#",
    //     // },
    //   ],
    // },

    // {
    //   title: "Learning",

    //   path: "/",
    //   img: "/images/sidebar icon/Learning-1.png.jpg",

    //   subItems: [
    //     {
    //       title: "Learning and Development",
    //       path: "/learning",
    //     },
    //   ],
    // },

    // {
    //   title: "Other Documents Repository",
    //   path: "/",
    //   img: "/images/sidebar icon/Other-Documents-Repository-2.jpg",

    //   subItems: [
    //     {
    //       title: "Company Policy",
    //       path: "/other-document-repository",
    //       title1: "Other Documents Repository",
    //     },

    //     {
    //       title: "Newsletters",
    //       path: "/other-document-repository",
    //       title1: "Other Documents Repository",
    //     },

    //     {
    //       title: "Code of Conduct",
    //       path: "/other-document-repository",
    //       title1: "Other Documents Repository",
    //     },

    //     {
    //       title: "Frequently and asked Questions",
    //       path: "/other-document-repository",
    //       title1: "Other Documents Repository",
    //     },

    //     {
    //       title: "Circular",
    //       path: "/other-document-repository",
    //       title1: "Other Documents Repository",
    //     },
    //   ],
    // },
  ];

  const portals = [
    {
      title: `${localeFunction("Internal_Portal")}`,
      link: "https://sarus.aicofindia.com:3030/",
    },
    {
      title: `${localeFunction("External_Portal")}`,
      link: "https://sarus-elb-345957920.ap-south-1.elb.amazonaws.com:8080/",
    },
  ];

  const headCells = [
    {
      id: "Name",
      numeric: false,
      disablePadding: true,
      label: `${localeFunction("Name")}`,
    },
    {
      id: "Modified",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("Created")}`,
    },
    {
      id: "ModifiedBy",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("CreatedBy")}`,
    },
  ];

  const InstitutionalheadCells = [
    {
      id: "Name",
      numeric: false,
      disablePadding: true,
      label: `${localeFunction("Name")}`,
    },
    // {
    //   id: "",
    //   numeric: false,
    //   disablePadding: true,
    //   label: `${localeFunction("Name")}`,
    // },
    {
      id: "CreatedBy",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("CreatedBy")}`,
    },
    {
      id: "Modified",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("Modified")}`,
    },
    {
      id: "ModifiedBy",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("ModifiedBy")}`,
    },
  ];

  const domainHeadCells = [
    {
      id: "Name",
      numeric: false,
      disablePadding: true,
      label: `${localeFunction("Name")}`,
    },
    {
      id: "CreatedBy",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("CreatedBy")}`,
    },
    {
      id: "ApprovalStatus",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("ApprovalStatus")}`,
    },
    {
      id: "Created",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("Created")}`,
    },
  ];

  const mainHeadCells = [
    {
      id: "QHeader",
      numeric: false,
      disablePadding: true,
      label: `${localeFunction("QHeader")}`,
    },
    {
      id: "ParliamentAssembly",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("ParliamentAssembly")}`,
    },
    {
      id: "Session",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("Session")}`,
    },
    {
      id: "State",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("State")}`,
    },
    {
      id: "Ministry",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("Ministry")}`,
    },
    {
      id: "QuestionNo",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("QuestionNo")}`,
    },
    {
      id: "QType",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("QType")}`,
    },
    {
      id: "ProductScheme",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("ProductScheme")}`,
    },
    {
      id: "Created",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("Created")}`,
    },
  ];

  const OhterDoucHeadCells = [
    {
      id: "Name",
      numeric: false,
      disablePadding: true,
      label: `${localeFunction("Name")}`,
    },
    {
      id: "CreatedBy",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("CreatedBy")}`,
    },
    {
      id: "ApprovalStatus",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("ApprovalStatus")}`,
    },
  ];

  const ShareHeadCells = [
    {
      id: "id",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("Srnumber")}`,
    },
    {
      id: "Name",
      numeric: false,
      disablePadding: true,
      label: `${localeFunction("Name")}`,
    },
    {
      id: "count",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("count")}`,
    },

    {
      id: "Created",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("CreatedBy")}`,
    },
  ];

  const contactHeadcells = [
    {
      id: "FirstName",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("First_Name")}`,
    },
    {
      id: "LastName",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("Last_Name")}`,
    },
    {
      id: "Location",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("Location")}`,
    },
    {
      id: "BussinessPhone",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("Bussiness_Phone")}`,
    },
    {
      id: "emailAddress",
      numeric: true,
      disablePadding: false,
      label: `${localeFunction("EMail_Address")}`,
    },
  ];
  const authData = {
    GetProfileFunction: (value) => GetProfileFunction(value),
    setIsLogin: (value) => setIsLogin(value),
    // csrf_token_Generate: (value) => csrf_token_Generate(value),
    // _getcsrf_token: _getcsrf_token,
    // csrf_token_Generate: csrf_token_Generate,
    _language: _language,
    addWaterMarkFunc: addWaterMarkFunc,
    serch_compiant_data: serch_compiant_data,
    serachCompaintApi: (value) => serachCompaintApi(value),
    setLanguage: (type) => setLanguage(type),
    SendEmailApi: (props) => SendEmailApi(props),
    setPage: setPage,
    _count: _count,
    profileData: profileData,
    isLoading: isLoading,
    isLogin: isLogin,
    // _getcsrf_token: _getcsrf_token,
    selectedMenu: selectedMenu,
    setSelectedMenu: setSelectedMenu,
    // setBaseFontSize: (size) => setBaseFontSize(size),
    // baseFontSize,
    menuList,
    portals,
    headCells,
    InstitutionalheadCells,
    domainHeadCells,
    mainHeadCells,
    OhterDoucHeadCells,
    ShareHeadCells,
    contactHeadcells,
  };
  return (
    <AuthContext.Provider value={authData}>
      {props.children}
    </AuthContext.Provider>
  );
}
